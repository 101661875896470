export const tutorialText = {
    h: "Help",
    content: [
        {
            h: "Tip",
            p: "Use Ctrl+f to quickly find what you are looking for"
        },{
            h3: "Presentation",
            h: "Basic usage",
            p: "3Dbinpacker is a free to use transport planifier with a 3D-4D bin packing and visualization tool. Our aim is to help you optimize your container loads, minimize your trips and maximize your profitability. This is a way for us to give back to the open source community. We do not use cookies, trackers or ads nor do we collect information from our users. If you wish to contribute or bring something to our notice, please contact us at swell-wave.com."
        },{
            h: "Known bugs",
            l: ["Currently all known bugs have been fixed"]
        },{
            h3: "Website architecture",
            h: "Main navigation",
            l: ["The Calculator tab displays a single cargo and bin preview as well as the packing results in 3D for the current selected trip.", "The Data tab contains all input used by the packing algorithm. On desktop, this input can also be modified from the left sidebar.", "The Details tab presents a detailed income & costs breakdown as well as an estimate of profitability and bin usage overview.", "The Help panel contains the website's documentation.", "The Changelog tab displays the current state of the website and the calculator.", "The Support tab redirects to our Paypal support page."]
        },{
            h: "Left sidebar & Data tab",
            p: "The left sidebar contains all data, such as options, cargo and bins, provided to the packing calculator. The input can also be modified from the Data tab. The sidebar contains the following tools and information:",
            l: ["Options & global price handling", "Packing manager (save & load config)", "Driving distance estimator", "Cargo details", "Bin(s)"]
        },{
            h: "Central panel",
            p: "The central panel holds the main content of the current location and displays the status of the calculator. This panel contains:",
            l: ["Calculator: the 3D visualization tool", "Data: the fields to input cargo & bins", "Details: an overview of the packing & financial results", "Help: the documentation"]
        },{
            h: "3D visualization",
            p: "Displays the current selected trip, cargo or bin as a 3D interactive model. The following actions are available:",
            l: ["Left mouse button click on cargo: select/unselect cargo (only in results panel)", "Left mouse button: rotate camera", "Right mouse button: move camera", "Mouse scroll: zoom"]
        },{
            h: "Right sidebar & Results navigation",
            p: "The right sidebar displays the results of the packing algorithm and enables you to easily navigate through trips and individual cargo items. The sidebar contains the following information:",
            l: ["Results overview: percentage of packed cargo, number of items, number of trips needed to transport all cargo, current selected trip and current used bin", "Download results in CSV format", "Trip selection and navigation", "Packed cargo details and individual item placement"]
        },{
            h3: "Options",
            h: "Minimize bin use",
            l: ["When unchecked, the bins will be used in the order you provided","When checked, the number of bins needed to transport the given cargo will be minimized regardless of the provided order"]
        },{
            h: "Currency",
            p: "Set your preferred currency. This enables you to automatically, although optionally, set local prices for VAT and propellant."
        },{
            h: "Set default prices",
            p: "According to your selected currency, set VAT and fuel price for all bins to their average local levels."
        },{
            h: "Compute distance",
            p: "Compute driving distance. The tool chooses the shortest and least expensive path. Each bin's \"Trip distance\" value is updated with the result."
        },{
            h: "Packing manager",
            p: "Manage, load or delete your saved fleet and cargo configurations."
        },{
            h: "Save current",
            p: "Save the current cargo and bin configuration to the packing manager."
        },{
            h: "Kill current process",
            p: "Stop all calculations and discard intermediate results."
        },{
            h3: "Cargo",
            h: "Item summary",
            p: "The item summary displays the cargo type, its dimensions and selected color. When clicked, the item's details will be displayed."
        },{
            h: "Delete",
            p: "Remove the current item from the list."
        },{
            h: "Color",
            p: "Set the color of the item in the 3D model."
        },{
            h: "Type",
            p: "Set the type of the item. This has no effect on the packing."
        },{
            h: "Length",
            p: "Set the length in millimeters of the item to be packed."
        },{
            h: "Width",
            p: "Set the width in millimeters of the item to be packed."
        },{
            h: "Height",
            p: "Set the height in millimeters of the item to be packed."
        },{
            h: "Weight",
            p: "Set the weight in kilograms of the item to be packed."
        },{
            h: "Quantity",
            p: "Set the number of items to be packed. The time needed to pack cargo grows exponentially with its quantity."
        },{
            h: "Add",
            p: "Add a new item. The new item will be initialized with the same configuration as the last cargo item."
        },{
            h3: "Bin details",
            h: "Bin summary",
            p: "The bin summary displays each bin's type, number and dimensions When clicked, the bin's details will be displayed."
        },{
            h: "Delete",
            p: "Remove the current bin from the list."
        },{
            h: "Type",
            p: "Set the type of the bin. When setting a new type, the bin dimensions and data as well as tarification details will automatically be updated."
        },{
            h: "Length",
            p: "Set the length in millimeters of the loadable compartment."
        },{
            h: "Width",
            p: "Set the width in millimeters of the loadable compartment."
        },{
            h: "Height",
            p: "Set the height in millimeters of the loadable compartment."
        },{
            h: "Weight",
            p: "Set the total weight in kilograms of the empty vehicle."
        },{
            h: "Tonnage",
            p: "Set the maximum load in kilograms that the vehicle can carry."
        },{
            h: "Add",
            p: "Add a new bin. The new bin will be initialized with the same configuration as the last bin. This way, you only need to configure bin costs and tarification details once for similar vehicles."
        },{
            h3: "Bin costs",
            h: "Initial price",
            p: "Set the initial price you payed for the vehicle. This will be used to calculate the profitability of the run."
        },{
            h: "Propellant",
            p: "Set the type of fuel the vehicle uses."
        },{
            h: "Propellant price",
            p: "Set the price of the selected propellant. This setting can be updated globally by clicking on Set Default Prices in the Options panel."
        },{
            h: "Insurance",
            p: "Set the yearly vehicle's insurance premium."
        },{
            h: "Tax",
            p: "Set the yearly vehicle tax amount."
        },{
            h: "Maintenance",
            p: "Set the yearly costs for maintenance."
        },{
            h: "Trip distance",
            p: "Set the distance in kilometers of the planned trip. This distance can also be computed and globally set from the Options panel."
        },{
            h: "Yearly distance",
            p: "Set the average yearly distance the vehicle is driven."
        },{
            h: "Tarification",
            p: "Please refer to the next section."
        },{
            h3: "Tarification",
            h: "Rate formula",
            p: "(m3R * m3) + [(kmR * km) ^ kmC] + [(kgR * kg) ^ kgC]",
            l: ["m3R: volumetric rate, namely your rate per cubic meter","m3: total cargo volume in cubic meters","kmR: kilometric rate, namely your rate per kilometer","km: trip distance in kilometers", "kmC: distance coefficient", "kgR: weight rate, namely your rate per kilogram of cargo","kg: total weight of cargo in kilograms", "kgC: weight coefficient"]
        },{
            h: "Kilometric rate",
            p: "Set your base rate per kilometer. Set to 0, if you do not use a kilometric rate."
        },{
            h: "Volumetric rate",
            p: "Set your base rate per cubic meter. Set to 0, if you do not use a volumetric rate."
        },{
            h: "Weight rate",
            p: "Set your base rate per kilogram. Set to 0, if you do not use a weight rate."
        },{
            h: "Distance coefficient",
            p: "The distance coefficient adds non-linearity to your base kilometric rate.",
            l: ["When set to 0, the base rate is nullified (no kilometric rate)", "When set below 1, the base rate decreases with each additional kilometer", "When set to 1, the coefficient has no effect and the base rate will always be applied", "When set over 1, the base rate increases with each additional kilometer"]
        },{
            h: "Weight coefficient",
            p: "The weight coefficient adds non-linearity to your base weight rate.",
            l: ["When set to 0, the base rate is nullified (no weight rate)", "When set below 1, the base rate decreases with each additional kilogram", "When set to 1, the coefficient has no effect and the base rate will always be applied", "When set over 1, the base rate increases with each additional kilogram"]
        },{
            h: "Minimum flat rate",
            p: "Set your minimum rate. If the computed rate is lower than this amount, this amount will be used as final rate to estimate your profitability."
        }
    ]
};